<template>
  <span :class="classes">
    <transition :name="$style.fade">
      <svg
        :class="$style.icon"
        v-if="value"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM10 17.5L5 12.543L6.59 11L10 14.346L17.409 7L19 8.577L10 17.5Z"
          :fill="fill"
        />
      </svg>
    </transition>
    <input
      :class="$style.input"
      @click="onChange"
      type="checkbox"
      :required="required"
      :checked="value"
    />
  </span>
</template>

<script lang="ts" setup>
import { useCssModule, computed } from 'vue'
import { BASE_PROPS_DEFAULTS } from './consts'
import type { BaseProps } from './types'

defineOptions({
  name: 'UiCheckbox'
})

const props = withDefaults(
  defineProps<
    BaseProps & {
      value?: boolean
    }
  >(),
  {
    ...BASE_PROPS_DEFAULTS,

    value: false
  }
)

const emit = defineEmits<{
  input: [value: boolean]
}>()

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.checkbox,
  [cssModule[`size-${props.size}`]],
  { [cssModule.active]: props.value }
])

const fill = computed(() => {
  if (props.theme === 'accent') {
    return '#00C7B1'
  }

  return '#fff'
})

const onChange = () => {
  emit('input', !props.value)
}
</script>

<style lang="scss" module>
.checkbox {
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  &.active {
    border-color: transparent;
  }
}
.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.icon {
  width: 100%;
  height: 100%;
}
.size-small {
  border-radius: 3px;
  width: 1.4em;
  height: 1.4em;
}
.size-medium {
  border-radius: 3px;
  width: 2em;
  height: 2em;
}

.fade {
  &:global(-leave-active),
  &:global(-enter-active) {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
