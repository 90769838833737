<template>
  <div :class="classes">
    <label :class="$style.label">
      <ui-checkbox
        :class="$style.checkbox"
        :theme="theme"
        :size="size"
        :value="modelValue"
        :required="required"
        @input="onInput"
      />
      <span :class="$style.t"><slot /></span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { useCssModule, computed } from 'vue'
import UiCheckbox from './checkbox.vue'
import { BASE_PROPS_DEFAULTS } from './consts'
import type { BaseProps } from './types'

defineOptions({
  name: 'UiCheckboxField'
})

const props = withDefaults(
  defineProps<
    BaseProps & {
      modelValue?: boolean
    }
  >(),
  {
    ...BASE_PROPS_DEFAULTS,

    modelValue: false
  }
)

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.container,
  [cssModule[`size-${props.size}`]]
])

const onInput = (val: boolean) => {
  emit('update:modelValue', val)
}
</script>

<style lang="scss" module>
.container {
}
.label {
  display: flex;
  align-items: flex-start;
}
.t {
  font-weight: 500;
  a {
    color: #8aeebe;
    &:hover {
      text-decoration: underline;
    }
  }
}

.size-small {
  .checkbox {
    margin-right: 0.8em;
  }
  .t {
    font-size: 1.2em;
    line-height: 120%;
    letter-spacing: 0.02em;
  }
}

.size-medium {
  .checkbox {
    margin-right: 1.2em;
  }
  .t {
    font-size: 1.3em;
    line-height: 143%;
    letter-spacing: 0.02em;
  }
}
</style>
